<template>
  <div class="aigc-solutions pb50">
    <div class="banner">
      <ImageWithPlaceholder
        src="/image/aigc/ai.jpg"
        alt="ArcSoft AIGC Large Model Solutions for Smartphone"
      />
      <div class="aigc-box">
        <h1 class="aigc-title">
          ArcSoft AIGC Large Model Solutions for Smartphone
        </h1>
        <div class="item-list">
          <a href="#portrait-sketch" class="portrait-sketch">Portrait Sketch</a>
          <a href="#super-resolution" class="super-resolution"
            >Super Resolution</a
          >
          <a href="#video-object-eraser" class="video-object-eraser"
            >Video Object Eraser</a
          >
        </div>
      </div>
    </div>
    <div class="aigc-top content mt120">
      <p class="p-text">
        With the vast computing power, training facilities, deployment
        infrastructure, and algorithms development capabilities of the AI
        Computing Center, ArcSoft has released AI deep learning based solutions
        for smartphones with excellent results, ready for licensing to
        smartphone makers.
      </p>
    </div>
    <div class="mt30 aigc-top-img">
      <img src="/image/aigc/a1.jpg" alt="" />
    </div>

    <div id="portrait-sketch" class="sketch-warpper content pt80">
      <h2 class="h2-title sketch-title">
        ArcSoft AIGC Large Model Portrarit Sketch
      </h2>
      <p class="p-text mt30">
        ArcSoft AIGC DL engine enables the extraction of key information of the
        portrait in an image and draw article lines automatically and animatedly
        to generate a portrait sketch.
      </p>
      <img class="sketch-img" src="/image/aigc/a2.jpg" alt="" />
    </div>
    <div id="super-resolution" class="resolution-warpper">
      <div class="content">
        <h2 class="h2-title resolution-title pt80">
          ArcSoft AIGC Large Model Solutions
        </h2>
        <h2 class="resolution-title pl38">for Photo Super Resolution</h2>
        <p class="p-text resolution-text mt30">
          This solution uses Large Model (ViT or Stable Diffusion) on devices to
          generate realistic and natural image details and reduce image noises
          for high zoom-in ratios.
        </p>

        <div class="resolution-mt-img mt30">
          <div class="input">
            <img src="/image/aigc/p1.jpg" alt="" />
            <p>Input 480*312</p>
          </div>
          <div class="diffusion">
            <img src="/image/aigc/p2.jpg" alt="" />
            <p>Diffusion SR result 1920*1248</p>
          </div>
        </div>
        <div class="img-list mt30">
          <img src="/image/aigc/p3.jpg " alt="" />
          <div class="img-list-text">
            <span>Input 480*312</span>
            <span>CNN SR result 1920*1248</span>
            <span>Diffusion SR result 1920*1248</span>
          </div>
        </div>
      </div>
      <div class="cat-img-list mt30">
        <img src="/image/aigc/cat.jpg " alt="" />
        <div class="cat-img-list-text">
          <span>Input <br />(480*312)</span>
          <span
            >CNN SR result<br />
            (1920*1248)</span
          >
          <span
            >ViT SR result<br />
            (1920*1248)</span
          >
          <span
            >Diffusion SR result<br />
            (1920*1248)</span
          >
        </div>
      </div>
      <div class="cat-img-list mt30">
        <img src="/image/aigc/wall.jpg " alt="" />
        <div class="cat-img-list-text">
          <span>Input <br />(480*312)</span>
          <span
            >CNN SR result<br />
            (1920*1248)</span
          >
          <span
            >ViT SR result<br />
            (1920*1248)</span
          >
          <span
            >Diffusion SR result<br />
            (1920*1248)</span
          >
        </div>
      </div>
    </div>
    <div id="video-object-eraser" class="eraser-warpper pt80">
      <div class="content">
        <h2 class="eraser-title h2-title">
          AIGC Large Model Solutions for Video Object Eraser
        </h2>
        <p class="p-text mt30">
          Leveraging AIGC Large Model on mobile device technologies, this
          solution helps users intelligently remove the undesired objects from
          the video.
        </p>
        <p class="p-text right">
          Support to remove both moving object and still object
        </p>
        <p class="p-text right">Support to remove multiple objects at once</p>
        <p class="p-text right">
          Provide nature image quality similar as original area
        </p>
        <P class="p-text right"
          >Support video recorded by tripod or handheld
        </P>
        <P class="p-text right">Support tiny object or object with occlusion</P>
        <!-- <img class="mt30" src="/image/aigc/video.jpg" alt="" /> -->
        <!-- 视频 需要静音自动播放 -->
        <div class="video-container mt30">
          <video
            src="/image/aigc/video1.mp4"
            controls
            controlsList="nodownload noplaybackrate"
            disablepictureinpicture
          ></video>
        </div>
      </div>
      <!-- <div class="img-a3">
        <img class="mt30" src="/image/aigc/a3.jpg" alt="" />
      </div> -->
      <!-- <div class="content">
        <div class="img-text-box">
          <span>Before</span>
          <span>After</span>
        </div>
        <div class="img-a4">
          <img class="mt30" src="/image/aigc/a4.jpg" alt="" />
        </div>
        <div class="img-text-box mb50">
          <span>Before</span>
          <span>After</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import ImageWithPlaceholder from '@/components/ImageWithPlaceholder.vue';
export default {
  name: 'AigcSolutions',
  components: {
    ImageWithPlaceholder,
  },
  metaInfo: {
    title:
      'ArcSoft AIGC Large Model Solutions for Smartphones: Portrait Sketch, Super Resolution, and Video Object Eraser',
    meta: [
      {
        name: 'description',
        content:
          'ArcSoft’s AIGC Large Model Solutions for smartphones feature cutting-edge technologies such as Portrait Sketch, Super Resolution, and Video Object Eraser. These advanced tools enhance mobile imaging by creating detailed and artistic portrait sketches, delivering exceptional clarity in image resolution, and seamlessly removing unwanted objects from videos.',
      },
      {
        name: 'keywords',
        content:
          'ArcSoft AIGC Large Model Solutions, smartphone imaging, Portrait Sketch, Super Resolution, Video Object Eraser, mobile photography technology, image enhancement, video editing tools, advanced imaging solutions', // 关键词
      },
    ],
  },
};
</script>
<style lang="less" scoped>
.aigc-solutions {
  overflow: hidden;
  .content {
    width: 1000px;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding-top: 0.1px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .p-text {
    font-size: 16px;
    line-height: 18px;
    padding: 0 32px;
    margin: 8px 0;
  }
  .h2-title::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 40px;
    background: #e83021;
    vertical-align: bottom;
    margin-right: 24px;
  }
  .banner {
    width: 100%;
    position: relative;
    .aigc-box {
      display: flex;
      align-items: center;
      position: absolute;
      flex-direction: column;
      bottom: -60px;
      width: 100%;
      z-index: 2;
      .aigc-title {
        font-size: 54px;
        color: #fff;
      }
      .item-list {
        margin-top: 246px;
        height: 120px;
        display: inline-flex;
        box-sizing: border-box;
        background: linear-gradient(to bottom, #eaeaeb, #fff);
        a {
          font-size: 20px;
          width: 280px;
          list-style: none;
          border: 1px solid #d9d9d9;
          border-left: initial;
          box-sizing: border-box;
          padding: 15px 25px;
          position: relative;
          span {
            display: inline-block;
            width: 150px;
          }
          &:hover::after {
            display: block;
          }
          &:hover {
            color: #e83021;
          }
        }
        a:first-child {
          border-left: 1px solid #d9d9d9;
        }
        .portrait-sketch {
          background: url(/image/aigc/portrait.svg) no-repeat 92% 18px e('/')
              22px,
            url(/image/aigc/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/aigc/portrait-action.svg) no-repeat 92% 18px
                e('/') 22px,
              url(/image/aigc/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
        .super-resolution {
          background: url(/image/aigc/Super.svg) no-repeat 92% 18px e('/') 22px,
            url(/image/aigc/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/aigc/Super-action.svg) no-repeat 92% 18px
                e('/') 22px,
              url(/image/aigc/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
        .video-object-eraser {
          background: url(/image/aigc/video.svg) no-repeat 92% 18px e('/') 22px,
            url(/image/aigc/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/aigc/video-action.svg) no-repeat 92% 18px
                e('/') 22px,
              url(/image/aigc/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
      }
    }
  }
  .img-text-box {
    font-size: 16px;
    color: #222;
    display: flex;

    span {
      text-align: center;
      width: 50%;
      display: inline-block;
    }
  }
  .aigc-top-img {
    text-align: center;
    aspect-ratio: 1920 / 240;
    img {
      width: 100%;
    }
  }
  .sketch-warpper {
    .sketch-title {
      font-size: 40px;
      color: #222;
    }
    .sketch-img {
      width: 960px;
      height: 395px;
      margin: 30px auto;
    }
  }
  .resolution-warpper {
    background: #222;
    .resolution-title {
      line-height: 45px;
      font-size: 40px;
      color: #fff;
    }
    .resolution-text {
      color: #fff;
    }
    .resolution-mt-img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-size: 14px;
      aspect-ratio: 960 / 415;
      .input {
        aspect-ratio: 240 / 181;
        padding-right: 40px;
        img {
          width: 100%;
        }
      }
      .diffusion {
        aspect-ratio: 600 / 415;
        img {
          width: 100%;
        }
      }
      p {
        text-align: center;
        font-size: 16px;
      }
    }
    .img-list {
      aspect-ratio: 960 / 440;
      img {
        width: 100%;
      }
      .img-list-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          text-align: center;
          width: 33.3%;
          display: inline-block;
          font-size: 16px;
          color: #fff;
        }
      }
    }
    .cat-img-list {
      aspect-ratio: 1920 / 600;
      text-align: center;
      img {
        width: 100%;
      }
      .cat-img-list-text {
        display: flex;
        aspect-ratio: 1920 / 40;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        span {
          text-align: center;
          width: 25%;
          display: inline-block;
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
  .eraser-warpper {
    .eraser-title {
      font-size: 40px;
      color: #222;
    }
    .right {
      padding: 0 52px;
      background: url('/image/aigc/arrow.svg') no-repeat 37px 5px;
    }
    .img-a3 {
      text-align: center;
      aspect-ratio: 1920 / 500;
      img {
        width: 100%;
      }
    }
    .img-a4 {
      img {
        width: 960px;
        height: 500px;
      }
    }
    .video-container {
      width: 100%;
      video {
        width: 100%;
      }
    }
  }

  .mt120 {
    margin-top: 120px;
  }
  .mt30 {
    margin-top: 30px;
  }
  .mt50 {
    margin-top: 50px;
  }
  .mb50 {
    margin-bottom: 50px;
  }
  .pl38 {
    padding-left: 38px;
  }
}

//音量按钮
video::-webkit-media-controls-mute-button {
  display: none;
}
// 画中画
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
</style>
